<template>
	<Loading></Loading>
</template>

<script>

	import Loading from "@/view/Layout/Loading";
	export default {
		name: 'AuthConfirm'
		, components: {Loading}
		, props: ['']
		, data: function() {
			return {
				program: {
					not_header: true
					, not_footer: true
				}
				, social_code: 'MB00400003'
			}
		}
		, methods:{
			loginWithSocial: async function(e){
				try{
					if(this.type == 'join'){
						return await this.joinWithSocial(e)
					}
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_login_social
						, data: {
							member_social_certfc_code: this.social_code
							, social_certfc_code: e.access_token
						}
						, type: true
					})

					if(result.success){
						window.postMessage({
							key: 'event'
							, data: result.data
						})
						this.token_info = result.data
						// console.log('this.token_info', this.token_info)

						sessionStorage.removeItem('Error')
						if(result.data.session_token){
							await this.$encodeStorage.setSessionToken(result.data.session_token)
						}

						switch (result.data.member_state_code){
							default:
								this.$bus.$emit('to', {name: 'mafia009'})
								break;
							case 'MB00500001':
								this.$emit('click', { type: 'profile', is_login: true})
								break;
							case 'MB00500002':
								this.$bus.$emit('onPin', 'join')
								break;
							case 'MB00500003':
								this.$emit('click', { type: 'fav', is_login: true})
								break;
							case 'MB00500004':
								this.$emit('click', { type: 'cartel', is_login: true})
								break;
							case 'MB00500005':
								if(result.data.session_token){
									await this.$encodeStorage.setSessionToken(result.data.session_token)
								}
								await this.$encodeStorage.setItem('login_info', e)

								this.$bus.$emit('onPin', 'pin_login')
								break;
							case 'MB00500006':
								this.$bus.$emit('to', { name: 'mafia009', params: {type: 'stop', msg: result.message}})
								this.$emit('cancel')
								break;
							case 'MB00500007':
								this.$bus.$emit('to', { name: 'mafia009', params: {type: 'out', msg: result.message}})
								this.$emit('cancel')
								break;
							case 'MB00500008':
								this.$bus.$emit('to', { name: 'mafia009', params: {type: 'sleep', msg: result.message}})
								this.$emit('cancel')
								break;
						}
					}else{
						console.log(result)
						if((result.code).indexOf('E0014') > -1){
							await this.$encodeStorage.removeItem('login_info')
							this.$bus.$emit('to', { name: 'mafia009', params: {type: 'auth', msg: result.message}})
						}else {

							switch (result.code) {
								case 'E000500001':
									this.$bus.$emit('to', { name: 'mafia009', params: {type: 'not', msg: result.message}})
									break;
								case 'E000400012':
									this.$bus.$emit('to', { name: 'mafia009', params: {type: 'stop', msg: result.message}})
									break;
								case 'E000400013':
									this.$bus.$emit('to', { name: 'mafia009', params: {type: 'out', msg: result.message}})
									break;
								case 'E000400014':
									this.$bus.$emit('to', { name: 'mafia009', params: {type: 'sleep', msg: result.message}})
									break;
								case 'E001400003':
								case 'E001400004':
								case 'E001400008':
									await this.$encodeStorage.removeItem('login_info')
									throw result.code
								// throw this.$language.auth.expired_auth_login
								default:
									await this.$encodeStorage.removeItem('login_info')
									throw result.message
							}
						}
					}
				}catch (e) {
					console.log(e)
					this.error = e
					this.$emit('cancel')

					// this.$emit('to', { name: 'mafia009', params: { type: 'third', msg: e}})
					this.$emit('to', { name: '400', params: { type: e}})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, joinWithSocial: async function(e){

				let join_info = localStorage.getItem('join_info')

				join_info = JSON.parse(join_info)
				join_info.type = e.type
				join_info.email = e.email
				join_info.access_token = e.access_token

				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_join_social
						, data: {
							member_social_certfc_code: this.social_code
							, social_certfc_code: e.access_token
							, agree_stip_list:[
								{ stip_number: join_info.terms_version }
								, { stip_number: join_info.personal_version}
							]
						}
						, type: true
					})

					if(result.success){
						this.token_info = result.data
						// console.log('this.token_info', this.token_info)

						let certfc = this.$encodeStorage.getSessionCertfc()
						if(!certfc){
							throw 'not certfc'
						}else{
							if(result.data.session_token){
								await this.$encodeStorage.setSessionToken(result.data.session_token)
							}
						}

						switch (result.data.member_state_code){
							case 'MB00500001': default:
								this.$emit('click', { type: 'profile', is_login: true})
								break;
							case 'MB00500002':
								this.$emit('click', { type: 'join', is_login: true})
								break;
							case 'MB00500003':
								this.$emit('click', { type: 'fav', is_login: true})
								break;
							case 'MB00500004':
								this.$emit('click', { type:  'cartel', is_login: true})
								break;
							case 'MB00500005':
								this.$bus.$emit('to', { name: 'mafia009', params: {type: 'already', msg: result.message}})
								this.$emit('cancel')
								break;
							case 'MB00500006':
								this.$bus.$emit('to', { name: 'mafia009', params: {type: 'stop', msg: result.message}})
								this.$emit('cancel')
								break;
							case 'MB00500007':
								this.$bus.$emit('to', {name: 'mafia0101'})
								this.$emit('cancel')
								break;
							case 'MB00500008':
								this.$bus.$emit('to', { name: 'mafia009', params: {type: 'sleep', msg: result.message}})
								this.$emit('cancel')
								break;
						}
					}else{
						switch (result.code){
							case 'E000400012':
								this.$bus.$emit('to', { name: 'mafia009', params: {type: 'stop', msg: result.message}})
								break;
							case 'E000400013':
								this.$bus.$emit('to', { name: 'mafia009', params: {type: 'out', msg: result.message}})
								break;
							case 'E000400014':
								this.$bus.$emit('to', { name: 'mafia009', params: {type: 'sleep', msg: result.message}})
								break;
							case 'E000400045':
								this.$bus.$emit('to', { name: 'mafia009', params: {type: 'already', msg: result.message}})
								break
							default:
								throw result.message
						}
						this.$emit('cancel')
					}
				}catch (e) {
					console.log(e)
					this.error = e
					this.$emit('to', { name: 'mafia009', params: { type: 'third', msg: e}})
					this.$emit('cancel')
				}finally {
					this.$bus.$emit('on', false)
					/*
					if(process.env.VUE_APP_TYPE == 'sample'){
						const result = await this.$sample.postJoinWithKakao(this.code, this.kakao_return_url)
						console.log('postJoinWithKakao result', result)
						if(result.success){
							this.$emit('to', { name: 'mafia0101'})
						}else{
							this.$emit('to', { name: 'mafia009', params: { type: 'third', msg: result.message}})
						}
					}

					 */
				}
			}
		}

		, created() {
			this.$bus.$emit('onLoad', this.program)
			this.$bus.$emit('on', true)
			console.log(this.$route)
			let path = this.$route.fullPath
			let d = path.split('#')
			let t = d[1].split("&")
			let tt = t[0].replace('access_token=', '')

			let r = {
				type : 'google'
				, access_token: tt
			}

			window.opener.postMessage({
				key: 'event'
				, data: r
			})
		}
	}
</script>